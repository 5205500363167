import { Swiper, SwiperSlide } from "swiper/react";

// configure Swiper to use modules
// Swiper.use([Navigation, Pagination]);

// Import Swiper styles
import "swiper/swiper.scss";

export const CarouselGallery = (props) => {
  return (
    <div id="gallery" className="text-center">
      <div className="container">
        <div className="section-title" style={{ marginTop: "50px" }}>
          <h2>Gallery</h2>
          <p>Images of our working sites.</p>
        </div>

        {/* Only on Desktop */}
        <div className="container">
          <aside className="gallery">
            <div className="grid">
              <figure className="image1">
                <img
                  src="img/slider/RJK (1).jpeg"
                  alt="image1"
                  style={{
                    height: "100%",
                    minWidth: "-webkit-fill-available",
                  }}
                />
                <figcaption>Cardiff</figcaption>
              </figure>
              <figure className="image2">
                <img
                  src="img/slider/RJK (2).jpeg"
                  alt="image2"
                  style={{ minWidth: "-webkit-fill-available" }}
                />
                <figcaption>Cardiff</figcaption>
              </figure>
              <figure className="image3">
                <img
                  src="img/slider/RJK (3).jpeg"
                  alt="image3"
                  style={{ minWidth: "-webkit-fill-available" }}
                />
                <figcaption>Cardiff</figcaption>
              </figure>
              <figure className="image4">
                <img
                  src="img/slider/RJK (4).jpeg"
                  alt="image4"
                  style={{ minWidth: "-webkit-fill-available" }}
                />
                <figcaption>Cardiff</figcaption>
              </figure>
            </div>
          </aside>
        </div>

        {/* Only on mobile */}
        <Swiper
          spaceBetween={5}
          slidesPerView={1}
          autoplay={"delay=2000"}
          pagination={{
            clickable: true,
            el: "swiper-pagination",
            type: "bullets",
            bulletElement: "span",
          }}
          className="md-none"
          style={{ height: "500px" }}
        >
          {props.data
            ? props.data.map((d, i) => (
                <SwiperSlide className="">
                  <div className="">
                    <img
                      src={d.img}
                      alt="..."
                      className=""
                      style={{
                        objectFit: "cover",
                        height: "500px",
                        width: "auto",
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))
            : "loading"}
        </Swiper>
      </div>
    </div>
  );
};
